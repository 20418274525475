// stores/useToastStore.ts
import { defineStore } from 'pinia'
import { ref } from 'vue'

export interface Toast {
  id: string
  title: string
  description?: string
  action?: {
    label: string
    onClick: () => void
  }
  duration?: number
  level: 'info' | 'warning' | 'error' | 'success'
}

export const useToastStore = defineStore('toast', () => {
  const toasts = ref<Toast[]>([])

  function addToast(toast: Omit<Toast, 'id'>) {
    const id = generateToastId(toast)
    if (!toasts.value.some(t => t.id === id)) {
      toasts.value.push({ ...toast, id })
      if (toast.duration !== Infinity) {
        setTimeout(() => {
          removeToast(id)
        }, toast.duration || 5000)
      }
    }
  }

  function removeToast(id: string) {
    const index = toasts.value.findIndex(t => t.id === id)
    if (index !== -1) {
      toasts.value.splice(index, 1)
    }
  }

  function generateToastId(toast: Omit<Toast, 'id'>): string {
    const content = `${toast.title}${toast.description || ''}${toast.action?.label || ''}`
    let hash = 0
    for (let i = 0; i < content.length; i++) {
      const char = content.charCodeAt(i)
      hash = ((hash << 5) - hash) + char
      hash = hash & hash // Convert to 32-bit integer
    }
    return hash.toString(36)
  }

  return { toasts, addToast, removeToast }
})




export function useToast() {
  const toastStore = useToastStore()

  function sendToast(toast: Omit<Toast, 'id'>) {
    toastStore.addToast(toast)
  }

  return { sendToast }
}


