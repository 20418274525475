import { default as callbackG80R4hjDu0Meta } from "/opt/buildhome/repo/pages/account/callback.vue?macro=true";
import { default as metadataAGSEW7LMPFMeta } from "/opt/buildhome/repo/pages/account/edit/set/[id]/metadata.vue?macro=true";
import { default as promotersIqjNneDfKDMeta } from "/opt/buildhome/repo/pages/account/edit/set/[id]/promoters.vue?macro=true";
import { default as track_45idsdTiG2RgN7eMeta } from "/opt/buildhome/repo/pages/account/edit/set/[id]/track-ids.vue?macro=true";
import { default as loginJyEoNaXrVFMeta } from "/opt/buildhome/repo/pages/account/login.vue?macro=true";
import { default as me57nkL4HJ67Meta } from "/opt/buildhome/repo/pages/account/me.vue?macro=true";
import { default as register7TFrOk5dLUMeta } from "/opt/buildhome/repo/pages/account/register.vue?macro=true";
import { default as settingsUXPM5W67PGMeta } from "/opt/buildhome/repo/pages/account/settings.vue?macro=true";
import { default as uploadPxuWenqcRvMeta } from "/opt/buildhome/repo/pages/account/upload.vue?macro=true";
import { default as alert8oe6LAVw5OMeta } from "/opt/buildhome/repo/pages/dev/alert.vue?macro=true";
import { default as audiopdoxRmDgtcMeta } from "/opt/buildhome/repo/pages/dev/audio.vue?macro=true";
import { default as loadingGiZ9hgDuDwMeta } from "/opt/buildhome/repo/pages/dev/loading.vue?macro=true";
import { default as ssrOCeKAH20QpMeta } from "/opt/buildhome/repo/pages/dev/ssr.vue?macro=true";
import { default as tokenNPQ7Nv0biNMeta } from "/opt/buildhome/repo/pages/dev/token.vue?macro=true";
import { default as form0SQW7AX2awMeta } from "/opt/buildhome/repo/pages/form.vue?macro=true";
import { default as no_45uploadsq5FEdBxv98Meta } from "/opt/buildhome/repo/pages/help/no-uploads.vue?macro=true";
import { default as upload_45info3Rw2JpDSkaMeta } from "/opt/buildhome/repo/pages/help/upload-info.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as privacy_45policypnUDKF1OY5Meta } from "/opt/buildhome/repo/pages/privacy-policy.vue?macro=true";
import { default as _91query_93Lefhp4nFBfMeta } from "/opt/buildhome/repo/pages/search/[type]/[query].vue?macro=true";
import { default as _91id_93vy1RzpZ1QuMeta } from "/opt/buildhome/repo/pages/stream/[id].vue?macro=true";
import { default as terms_45of_45service4Xad5aMSOFMeta } from "/opt/buildhome/repo/pages/terms-of-service.vue?macro=true";
export default [
  {
    name: "account-callback",
    path: "/account/callback",
    component: () => import("/opt/buildhome/repo/pages/account/callback.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-set-id-metadata",
    path: "/account/edit/set/:id()/metadata",
    component: () => import("/opt/buildhome/repo/pages/account/edit/set/[id]/metadata.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-set-id-promoters",
    path: "/account/edit/set/:id()/promoters",
    component: () => import("/opt/buildhome/repo/pages/account/edit/set/[id]/promoters.vue").then(m => m.default || m)
  },
  {
    name: "account-edit-set-id-track-ids",
    path: "/account/edit/set/:id()/track-ids",
    component: () => import("/opt/buildhome/repo/pages/account/edit/set/[id]/track-ids.vue").then(m => m.default || m)
  },
  {
    name: "account-login",
    path: "/account/login",
    component: () => import("/opt/buildhome/repo/pages/account/login.vue").then(m => m.default || m)
  },
  {
    name: "account-me",
    path: "/account/me",
    component: () => import("/opt/buildhome/repo/pages/account/me.vue").then(m => m.default || m)
  },
  {
    name: "account-register",
    path: "/account/register",
    component: () => import("/opt/buildhome/repo/pages/account/register.vue").then(m => m.default || m)
  },
  {
    name: "account-settings",
    path: "/account/settings",
    component: () => import("/opt/buildhome/repo/pages/account/settings.vue").then(m => m.default || m)
  },
  {
    name: "account-upload",
    path: "/account/upload",
    component: () => import("/opt/buildhome/repo/pages/account/upload.vue").then(m => m.default || m)
  },
  {
    name: "dev-alert",
    path: "/dev/alert",
    component: () => import("/opt/buildhome/repo/pages/dev/alert.vue").then(m => m.default || m)
  },
  {
    name: "dev-audio",
    path: "/dev/audio",
    component: () => import("/opt/buildhome/repo/pages/dev/audio.vue").then(m => m.default || m)
  },
  {
    name: "dev-loading",
    path: "/dev/loading",
    component: () => import("/opt/buildhome/repo/pages/dev/loading.vue").then(m => m.default || m)
  },
  {
    name: "dev-ssr",
    path: "/dev/ssr",
    component: () => import("/opt/buildhome/repo/pages/dev/ssr.vue").then(m => m.default || m)
  },
  {
    name: "dev-token",
    path: "/dev/token",
    component: () => import("/opt/buildhome/repo/pages/dev/token.vue").then(m => m.default || m)
  },
  {
    name: "form",
    path: "/form",
    component: () => import("/opt/buildhome/repo/pages/form.vue").then(m => m.default || m)
  },
  {
    name: "help-no-uploads",
    path: "/help/no-uploads",
    component: () => import("/opt/buildhome/repo/pages/help/no-uploads.vue").then(m => m.default || m)
  },
  {
    name: "help-upload-info",
    path: "/help/upload-info",
    component: () => import("/opt/buildhome/repo/pages/help/upload-info.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/opt/buildhome/repo/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "search-type-query",
    path: "/search/:type()/:query()",
    component: () => import("/opt/buildhome/repo/pages/search/[type]/[query].vue").then(m => m.default || m)
  },
  {
    name: "stream-id",
    path: "/stream/:id()",
    component: () => import("/opt/buildhome/repo/pages/stream/[id].vue").then(m => m.default || m)
  },
  {
    name: "terms-of-service",
    path: "/terms-of-service",
    component: () => import("/opt/buildhome/repo/pages/terms-of-service.vue").then(m => m.default || m)
  }
]