import { defineStore } from 'pinia';
import { useEventBus } from '@vueuse/core';
import { useTitle } from '@vueuse/core';
import { useFetch } from '@vueuse/core';
import { useToast } from './useToastStore';



import mixpanel from 'mixpanel-browser'

export const useAudioPlayerStore = defineStore('audio-player', {
  state: () => ({
    currentTrack: null,
    queue: [],
    playerState: 'notplaying',
    player: null,
    volume: 1,
    isMuted: false,
    position: 0,
    isOwner: false,
    lastTracked: null,
    ignoreKeyboardShortcuts: false
  }),
  getters: {
    getCurrentSrc: (state) => {
      if (state.currentTrack) {
        return state.currentTrack.streamable_url || state.currentTrack.download_url;
      }
      return null;
    },
    getIsPlaying: (state) => state.playerState === 'playing',
    getIsMuted: (state) => state.isMuted,
    getVolume: (state) => state.volume,
  },
  actions: {
    setPosition(position) {
      this.position = position;
    },
    killCurrentPlayingTrack() {
      this.currentTrack = null;
      this.setPlayerState('notplaying');
    },
    playTrack(track) {
      const supabase = useSupabaseClient()
      const runtimeConfig = useRuntimeConfig()
      console.log("playTrack", track)
      this.currentTrack = track;
      this.setPlayerState('playing');
      useEventBus('audio-player').emit('play-track', track);

      // track play
      supabase.auth.getSession().then(({ data: { session } }) => {
        if (this.lastTracked == this.currentTrack.id) {
          // we've already tracked this track
          console.log("[aphex] already tracked this track -- skipping")
          return
        }


        mixpanel.track('Starting Stream', { set: this.currentTrack.id })

        var accessToken = session?.data?.session?.access_token
        var headers = {}
        if (accessToken) {
          headers['Authorization'] = `Bearer ${accessToken}`
          console.log("[aphex] tracking has access token")
        }

        // use normal fetch
        supabaseFetch(`${runtimeConfig.public.backend}/user/activity/t/play/${this.currentTrack.id}`, {
          method: 'POST',
          headers: headers
        }).then(response => {
          if (response.ok) {
            console.log("[aphex] tracked play")
            this.lastTracked = this.currentTrack.id
          } else {
            console.error("Error tracking play:", response.statusText);
          }
        }).catch(error => {
          console.error("Error tracking play:", error);
        })
      })

    },
    pauseTrack() {
      if (this.playerState === 'playing') {
        this.setPlayerState('paused');
        useEventBus('audio-player').emit('pause-track');
      }
    },
    resumeTrack() {
      if (this.playerState === 'paused' && this.currentTrack) {
        this.setPlayerState('playing');
        useEventBus('audio-player').emit('resume-track');
      }
    },
    stopTrack() {
      this.currentTrack = null;
      this.setPlayerState('notplaying');
      useEventBus('audio-player').emit('stop-track');
    },
    setVolume(volume) {
      this.volume = volume;
      useEventBus('audio-player').emit('set-volume', volume);
    },
    toggleMute() {
      this.isMuted = !this.isMuted;
      useEventBus('audio-player').emit('toggle-mute', this.isMuted);
    },
    setPlayerState(state) {

      this.playerState = state;

    },
    setCurrentTime(time) {
      console.log("[aphex] setting current time", time, "=> event name", `set-current-time:${time}`);
      useEventBus('audio-player').emit(`set-current-time:${time}`);
    },
    playNext(track) {
      if (this.currentTrack) {
        this.stopTrack();
      }
      this.playTrack(track);
    },
    addToQueue(track) {
      this.queue.push(track);
      if (this.queue.length === 1 && !this.currentTrack) {
        this.playNext(this.queue.shift());
      }
    },
    // New actions for play/pause
    play() {
      if (this.currentTrack) {
        if (this.playerState === 'paused') {
          this.resumeTrack();
        } else {
          this.playTrack(this.currentTrack);
        }
      } else if (this.queue.length > 0) {
        this.playNext(this.queue.shift());
      }
    },
    pause() {
      this.pauseTrack();
    },
  }
});




export const useStore = defineStore('main', {
  state: () => ({
    isAuthenticated: false,
    userId: null,
    userLikedArtists: [],
    userLikedTags: [],
    userLikedSets: []
  }),
  actions: {
    async getLikes() {
      console.log("[aphex] getting likes")
      let fetchLikesRequest = await supabaseFetch(
        `/user/activity/view-likes/sets`,
        {
          method: "GET",
        }
      )
      let fetchedLikesResponse = await fetchLikesRequest.json();
      console.log("[aphex] got likes", fetchedLikesResponse)
      // Keep only the id of the sets
      this.userLikedSets = fetchedLikesResponse.map(like => like.track_id)
      return fetchedLikesResponse
    },


    setIsAuthenticated(isAuthenticated) {
      this.isAuthenticated = isAuthenticated
    },
    setUserId(userId) {
      this.userId = userId
    },
    setUserLikedArtists(userLikedArtists) {
      this.userLikedArtists = userLikedArtists
    },
    setUserLikedTags(userLikedTags) {
      this.userLikedTags = userLikedTags
    },

    async addLike(setId) {
      const toast = useToast()
      // background we'll also send to the backend
      await supabaseFetch(`/user/activity/like/set/${setId}`, {
        method: 'POST',
      }).then(response => {
        if (response.ok) {
          console.log("[aphex] liked set")
          console.log("[aphex]", "locally adding like for set", setId)
          var localLikes = localStorage.getItem(`aphex-liked-${setId}`)
          if (localLikes) {
            localStorage.setItem(`aphex-liked-${setId}`, 'true')
          } else {
            localStorage.setItem(`aphex-liked-${setId}`, 'true')
          }
          console.log("[aphex]", "locally added like for set", setId, this.userLikedSets.filter(set => set !== setId))
          this.userLikedSets.push(setId)
        } else {
          console.error("Error liking set:", response.statusText);
          toast.sendToast({
            title: 'Error',
            description: 'There was an error liking this set. Please try again later.',
            level: 'error'
          })
        }
      }).catch(error => {
        console.error("Error liking set:", error);
        toast.sendToast({
          title: 'Error',
          description: 'There was an error liking this set. Please try again later.',
          level: 'error'
        })
      })

    },





    async removeLike(setId) {
      // background we'll also send to the backend
      await supabaseFetch(`/user/activity/dislike/set/${setId}`, {
        method: 'POST',
      }).then(response => {
        if (response.ok) {
          console.log("[aphex] disliked set")
          console.log("[aphex]", "locally removing like for set", setId)
          var localLikes = localStorage.getItem(`aphex-liked-${setId}`)
          if (localLikes) {
            localStorage.removeItem(`aphex-liked-${setId}`)
          }
          console.log("[aphex]", "locally removed like for set", setId, this.userLikedSets.filter(set => set !== setId))
          this.userLikedSets = this.userLikedSets.filter(set => set !== setId)
        } else {
          console.error("Error disliking set:", response.statusText);
          // send a toast?
          toast.sendToast({
            title: 'Error',
            description: 'There was an error disliking this set. Please try again later.',
            level: 'error'
          })
        }
      }).catch(error => {
        console.error("Error disliking set:", error);
        // send a toast?
        toast.sendToast({
          title: 'Error',
          description: 'There was an error disliking this set. Please try again later.',
          level: 'error'
        })
      })


    }
  },
})


export const useToastStore = defineStore('toast', {
  state: () => ({
    toasts: []
  }),
  actions: {
    addToast(toast) {
      const id = this.generateToastId(toast)
      if (!this.toasts.some(t => t.id === id)) {
        this.toasts.push({ ...toast, id })
        if (toast.duration !== Infinity) {
          setTimeout(() => {
            this.removeToast(id)
          }, toast.duration || 5000)
        }
      }
    },
    removeToast(id) {
      const index = this.toasts.findIndex(t => t.id === id)
      if (index !== -1) {
        this.toasts.splice(index, 1)
      }
    },
    generateToastId(toast) {
      const content = `${toast.title}${toast.description || ''}${toast.action?.label || ''}`
      let hash = 0
      for (let i = 0; i < content.length; i++) {
        const char = content.charCodeAt(i)
        hash = ((hash << 5) - hash) + char
        hash = hash & hash // Convert to 32-bit integer
      }
      return hash.toString(36)
    }
  }
});