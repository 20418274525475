// stores/useAlertStore.ts
import { defineStore } from 'pinia'

export type AlertLevel = 'info' | 'warning' | 'error' | 'success'

interface AlertOptions {
  title: string
  subtitle: string
  cancelText: string
  confirmText: string
  level: AlertLevel
}

export const useAlertStore = defineStore('alert', {
  state: () => ({
    isOpen: false,
    title: '',
    subtitle: '',
    cancelText: '',
    confirmText: '',
    level: 'info' as AlertLevel,
    resolvePromise: null as ((value: boolean) => void) | null,
  }),
  actions: {
    showAlert(options: AlertOptions) {
      this.isOpen = true
      this.title = options.title
      this.subtitle = options.subtitle
      this.cancelText = options.cancelText
      this.confirmText = options.confirmText
      this.level = options.level
      console.log("[aphex] Setting alert level in store:", this.level)


      // stupid ass ui bug, i will fix it later
      // move the viewport to the top of the page
      document.documentElement.scrollTo(0, 0)

      return new Promise<boolean>((resolve) => {
        this.resolvePromise = resolve
      })
    },
    hideAlert(result: boolean) {
      this.isOpen = false
      if (this.resolvePromise) {
        this.resolvePromise(result)
        this.resolvePromise = null
      }
    },
  },
})

export function useAlert() {
    const alertStore = useAlertStore()
  
    const showAlert = (options: {
      title: string
      subtitle: string
      cancelText: string
      confirmText: string,
      level?: AlertLevel
    }) => {
      console.log("[aphex] showing alert", options)
      return alertStore.showAlert(options)
    }
  
    return {
      showAlert,
    }
  }