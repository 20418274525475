<template>
    <div>
        <BetaBanner v-if="store.getIsAuthenticated" />

        <ClientOnly>
            <StickyAudioPlayer class="sticky top-0 z-50 border-b border-white/25" />
            <Toast />
        </ClientOnly>
        <NuxtPwaManifest />
        <NuxtLoadingIndicator  />
        <NuxtPage />
        <Alert />

    </div>
</template>

<script setup>

import { useStore } from '~/store'
import { useToast } from '@/store/useToastStore'
import mixpanel from 'mixpanel-browser'
let store = useStore()
var toast = useToast()

const { $pwa } = useNuxtApp()
const config = useRuntimeConfig()

onMounted(() => {

    mixpanel.init(
        config.public.mixpanel.token,
        { debug: true, track_pageview: true, persistence: 'localStorage' }
    )



    if (store.getIsAuthenticated) {
        toast.sendToast({
            title: 'Welcome back.',
            description: 'aphex streaming is in private beta, please send issues to bugs@aphex.live ',
            level: 'info'
        })
    }
    watch($pwa.needRefresh, (newValue) => {
        if (newValue) {
            toast.sendToast({
                title: 'Update required',
                description: "There is an update available for aphex. Please update to the latest version.",
                level: 'info',
                actions: [
                    {
                        label: 'Update',
                        onClick: () => {
                            $pwa.updateServiceWorker()
                        }
                    }
                ]
            })
        }
    })
    


})




useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} | aphex` : '| aphex';
    },
    bodyAttrs: {
        class: 'h-full bg-[#0A0A0A]'
    },
    htmlAttrs: {
        class: 'h-full'
    },
    link: [
        {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css?family=JetBrains Mono'
        }
    ],
    meta: [
        {
            name: 'og:logo',
            content: 'https://cdn.trackrr.cc/images/public/logo.png'
        }
    ],
    script: [
        {
            src: 'https://cdn.dashjs.org/latest/dash.all.min.js'
        }
    ]
})
</script>