<template>
  <div class="ticker-container" :style="{ height: `${height}px` }" ref="containerRef">
    <div class="ticker" ref="tickerRef">
      <div class="ticker-content" ref="contentRef">
        <template v-for="(item, index) in displayItems" :key="`${index}-${item.type}`">
          <span class="ticker-item">
            <span v-if="item.type === 'artistNames'" class="text-white/50 text-xs mr-2">
              {{ audioPlayerStore.getIsPlaying ? 'NOW PLAYING:' : '' }}
            </span>
            <template v-if="item.type === 'artistNames'">
              <span v-for="(artist, artistIndex) in item.artists" :key="artistIndex">
                <QueryableHref 
                  :query="artist" 
                  :allowHover="true" 
                  :useSlug="true"
                  :type="item.querable"
                />
                <span v-if="artistIndex < item.artists.length - 1" class="pr-2">, </span>
              </span>
            </template>
            <template v-else>
              <QueryableHref 
                v-if="item.querable" 
                :query="item.value" 
                :allowHover="true" 
                :useSlug="true"
                :type="item.querable" 
              />
              <NuxtLink v-else-if="item.url" :to="item.url" class="hover:underline cursor-pointer">{{ item.value }}</NuxtLink>
              <span v-else>{{ item.value }}</span>
            </template>
          </span>
          <span class="ticker-separator" v-if="index < displayItems.length - 1">•</span>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed } from 'vue'
import { useAudioPlayerStore } from '~/store'
import { useElementHover, useRafFn, useResizeObserver } from '@vueuse/core'

const audioPlayerStore = useAudioPlayerStore()

const props = defineProps({
  height: { type: Number, default: 30 },
  speed: { type: Number, default: 50 } // pixels per second
})

const containerRef = ref(null)
const tickerRef = ref(null)
const contentRef = ref(null)
const isHovered = useElementHover(containerRef)

const tickerItems = computed(() => {
  if (!audioPlayerStore.currentTrack) return []

  const { metadata } = audioPlayerStore.currentTrack
  return [
    metadata.artist.length > 0 && { type: 'artistNames', artists: metadata.artist, querable: 'artist' },
    metadata.title && { type: 'title', value: metadata.title, url: `/stream/${audioPlayerStore.currentTrack.id}` },
    metadata.promoter && { type: 'promoter', value: metadata.promoter, querable: 'promoter' },
    metadata.festival && { type: 'festival', value: metadata.festival, querable: 'festival' },
    metadata.date && { type: 'date', value: metadata.date }
  ].filter(Boolean)
})

const displayItems = computed(() => [...tickerItems.value, ...tickerItems.value])

let currentPosition = 0
let contentWidth = 0
let containerWidth = 0

const updateDimensions = () => {
  if (contentRef.value && containerRef.value) {
    contentWidth = contentRef.value.offsetWidth / 2 // Divide by 2 because we duplicated the content
    containerWidth = containerRef.value.offsetWidth
  }
}

const { pause, resume } = useRafFn(() => {
  if (isHovered.value) return

  currentPosition += props.speed / 60 // Assuming 60 FPS
  if (currentPosition >= contentWidth) {
    currentPosition = 0
  }

  if (tickerRef.value) {
    tickerRef.value.style.transform = `translateX(${-currentPosition}px)`
  }
}, { immediate: false })

onMounted(() => {
  updateDimensions()
  resume()
})

onUnmounted(() => {
  pause()
})

watch(() => audioPlayerStore.currentTrack, () => {
  currentPosition = 0
  updateDimensions()
})

watch(() => props.speed, resume)

watch(isHovered, (newValue) => {
  if (newValue) {
    pause()
  } else {
    resume()
  }
})

useResizeObserver(containerRef, () => {
  updateDimensions()
})

useResizeObserver(contentRef, () => {
  updateDimensions()
})
</script>

<style scoped>
.ticker-container {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.ticker {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  height: 100%;
  display: flex;
  align-items: center;
}

.ticker-content {
  display: inline-flex;
  align-items: center;
}

.ticker-item {
  display: inline-flex;
  align-items: center;
}

.ticker-separator {
  margin: 0 15px;
  opacity: 0.5;
}
</style>