<template>
  <div class="w-full bg-black text-white flex flex-col sticky top-0 z-50">
    <div>
      <AudioPlayer
        v-if="audioPlayerStore.currentTrack"
        :src="audioPlayerStore.getCurrentSrc"
        :metadata="audioPlayerStore.currentTrack.metadata"
        :id="audioPlayerStore.currentTrack.id"
        :is-desktop="isDesktop"
        @paused="audioPlayerStore.setPlayerState('paused')"
        @playing="audioPlayerStore.setPlayerState('playing')"
      />
    </div>
    <div class="my-1" v-if="audioPlayerStore.currentTrack">
      <Ticker :height="30" :speed="40" />
    </div>
  </div>
</template>

<script setup>
import { useAudioPlayerStore } from '~/store'
import Ticker from './Ticker.vue'
const { isMobile, isDesktop } = useDevice();


const audioPlayerStore = useAudioPlayerStore()






</script>