<template>
    <div class="toast-container">
        <TransitionGroup enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
            leave-to-class="opacity-0">
            <div v-for="toast in toasts" :key="toast.id"
                class="toast pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg  border border-white/25 shadow-lg ring-1 ring-white ring-opacity-5 mb-4"
                :class="{
                    'bg-[#022200] border-green-400': toast.level === 'success',
                    'bg-[#221E00] border-yellow-400': toast.level === 'warning',
                    'bg-[#000722] border-blue-400': toast.level === 'info',
                    'bg-[#220000] border-red-400': toast.level === 'error'
                }">  
                <div class="p-4">
                    <div class="flex items-start">
                        <div class="flex-shrink-0">
                            
                            <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" v-if="toast.level === 'success'" />
                            <ExclamationCircleIcon class="h-6 w-6 text-yellow-400" aria-hidden="true" v-else-if="toast.level === 'warning'" />
                            <InformationCircleIcon class="h-6 w-6 text-blue-400" aria-hidden="true" v-else-if="toast.level === 'info'" />
                            <XCircleIcon class="h-6 w-6 text-red-500/50" aria-hidden="true" v-else />
                        </div>
                        <div class="ml-3 w-0 flex-1 pt-0.5">
                            <p class="text-sm font-medium text-white">{{ toast.title }}</p>
                            <p v-if="toast.description" class="mt-1 text-sm text-gray-300">
                                
                                {{ toast.description }}</p>
                            <div v-if="toast.action" class="mt-2">
                                <button
                                    @click="toast.action.onClick"
                                    class="text-sm font-medium text-white hover:text-gray-300 focus:outline-none focus:underline"
                                >
                                    {{ toast.action.label }}
                                </button>
                            </div>
                        </div>
                        <div class="ml-4 flex flex-shrink-0">
                            <button type="button" @click="removeToast(toast.id)"
                                class="inline-flex rounded-md text-gray-400 hover:text-gray-300 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-black">
                                <span class="sr-only">Close</span>
                                <XMarkIcon class="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </TransitionGroup>
    </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { storeToRefs } from 'pinia'
import { CheckCircleIcon, ExclamationCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/vue/24/solid'
import { XMarkIcon } from '@heroicons/vue/20/solid'
import { useToastStore, useToast } from '@/store/useToastStore'

const toastStore = useToastStore()
let toast = useToast()
const { toasts } = storeToRefs(toastStore)

// Extend Window interface
declare global {
    interface Window {
        sendToast: () => void;
    }
}

function removeToast(id: string) {
    toastStore.removeToast(id)
}

onMounted(() => {
    // for development purposes, we can add the sendToast function to the window object
    function developmentSendToast() {
        toast.sendToast({
            title: 'Test notification',
            description: 'This is a test notification' + new Date().toISOString(),
            action: {
                label: 'Undo',
                onClick: () => {
                    console.log('Undo clicked')
                }
            }, 
            level: 'success'
        })
    }

    window.sendToast = developmentSendToast

//
})
</script>

<style scoped>
.toast-container {
    position: fixed;
    top: calc(env(safe-area-inset-top, 16px) + 64px);
    /* Adjust 64px based on your StickyAudioPlayer height */
    left: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    z-index: 60;
    /* Ensure it's above the StickyAudioPlayer */
}

.toast {
    pointer-events: auto;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

@media (min-width: 640px) {
    .toast-container {
        align-items: flex-end;
    }
}
</style>