<template>
  <!-- <div hidden class="flex items-center gap-x-6 bg-white px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
    <p class="text-sm leading-6 text-white">
    <div class="text-black">
      <strong class="font-semibold tracking-tighter pr-4">ʕ •ᴥ• ʔ</strong>
      <Transition name="fade">
        <span v-if="isVisible" class="inline-block">
          {{ currentMessage }}
        </span>
      </Transition>
    </div>
    </p>
    <div class="flex flex-1 justify-end">

    </div>
  </div> -->
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import { useToast } from '@/store/useToastStore'
const messages = [
  "aphex streaming is in private beta, please send issues to bugs@aphex.live",
  "know a dj who can help? send an email to support@aphex.live",
  "all sets uploaded will still be available post-launch",
  "send feedback to bugs@aphex.live, with ideas or suggestions",
  "we're focusing on improving streaming for mobile devices. send feedback to bugs@aphex.live",
];

const currentMessageIndex = ref(0);
const isVisible = ref(true);
const currentMessage = computed(() => messages[currentMessageIndex.value]);
const toast = useToast()
let intervalId;

const cycleMessage = () => {
  isVisible.value = false;
  setTimeout(() => {
    currentMessageIndex.value = (currentMessageIndex.value + 1) % messages.length;
    isVisible.value = true;
  }, 250); // Wait for fade-out before changing message
};

onMounted(() => {
  intervalId = setInterval(cycleMessage, 12000); // Change message every 5 seconds

  toast.sendToast({
    title: 'Welcome back.',
    description: 'aphex streaming is in private beta, please send issues to bugs@aphex.live ',
    level: 'info'
  })

});

onUnmounted(() => {
  clearInterval(intervalId);
});

defineEmits(['dismiss']);
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>