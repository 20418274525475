<script setup lang="ts">
import { ref, watch } from 'vue'
import { SliderRange, SliderRoot, SliderThumb, SliderTrack } from 'radix-vue'

const emit = defineEmits(['seek'])

const props = defineProps({
    max: {
        type: Number,
        default: 100
    },
    currentTime: {
        type: Number,
        default: 0
    }
})

const sliderValue = ref([props.currentTime])

// Watch for changes in currentTime prop
watch(() => props.currentTime, (newTime) => {
  // Update sliderValue when currentTime changes
  sliderValue.value = [newTime]
})

// Watch for changes in max prop
watch(() => props.max, () => {
  // Reset sliderValue to 0 when max changes (indicating a new song)
  sliderValue.value = [0]
})
</script>

<template>
  <SliderRoot
    v-model="sliderValue"
    class="relative flex items-center select-none touch-none w-full h-5"
    :max="max"
    :step="1"
    @update:model-value="emit('seek', $event[0])"
  >
    <SliderTrack class="bg-white/20 relative grow rounded-full h-1">
      <SliderRange class="absolute bg-white/75 rounded-full h-full" />
    </SliderTrack>
    <SliderThumb
      class="block w-2 h-5 bg-white hover:cursor-grab hover:w-4 focus:w-4  hover:bg-white/90 transition-all ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-white/50"
      aria-label="Seek"
    />
  </SliderRoot>
</template>