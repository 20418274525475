
<template>
  <AlertDialogRoot :open="alertStore.isOpen">
    <AlertDialogPortal>
      <Transition name="fade">
        <AlertDialogOverlay v-if="alertStore.isOpen" class="fixed inset-0 z-[9998] bg-black bg-opacity-85" />
      </Transition>
      <Transition name="fade">
        <AlertDialogContent
          v-if="alertStore.isOpen"
          :class="[
            'fixed z-[9999] top-1/2 left-1/2 max-h-[85vh] w-[90vw] max-w-[500px] -translate-x-1/2 -translate-y-1/2 rounded-[6px] p-[25px] shadow-lg focus:outline-none border-2',
            currentLevelClasses.bg,
            currentLevelClasses.border
          ]"
        >
          <AlertDialogTitle :class="['m-0 text-[17px] font-semibold', currentLevelClasses.text]">
            {{ alertStore.title }}
          </AlertDialogTitle>
          <AlertDialogDescription class="mt-4 mb-5 text-[15px] leading-normal text-white">
            {{ alertStore.subtitle }}
          </AlertDialogDescription>
          <div class="flex justify-end gap-[25px]">
            <AlertDialogCancel
              :class="[
                'inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-semibold leading-none outline-none',
                'bg-white/5 text-white hover:bg-white/20 focus:ring-2 focus:ring-white/50'
              ]"
              @click="handleCancel"
            >
              {{ alertStore.cancelText }}
            </AlertDialogCancel>
            <AlertDialogAction
              :class="[
                'inline-flex h-[35px] items-center justify-center rounded-[4px] px-[15px] font-semibold leading-none outline-none focus:ring-2',
                currentLevelClasses.text,
                currentLevelClasses.border,
                `hover:bg-white/50 focus:ring-white/50 transition-colors duration-300`,
              ]"
              @click="handleConfirm"
            >
              {{ alertStore.confirmText }}
            </AlertDialogAction>
          </div>
        </AlertDialogContent>
      </Transition>
    </AlertDialogPortal>
  </AlertDialogRoot>
</template>


<script setup lang="ts">
import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogRoot,
  AlertDialogTitle,
} from 'radix-vue'
import { useAlertStore } from "@/store/useAlertStore"
import { computed } from 'vue'

const alertStore = useAlertStore()

function handleConfirm() {
  alertStore.hideAlert(true)
}

function handleCancel() {
  alertStore.hideAlert(false)
}

const levelClasses = {
  'info': {
    bg: 'bg-aphex-info-bg',
    border: 'border-aphex-info-border',
    text: 'text-white'
  },
  'warning': {
    bg: 'bg-aphex-warning-bg',
    border: 'border-aphex-warning-border',
    text: 'text-white'
  },
  'error': {
    bg: 'bg-aphex-error-bg',
    border: 'border-aphex-error-border',
    text: 'text-white'
  },
  'success': {
    bg: 'bg-aphex-success-bg',
    border: 'border-aphex-success-border',
    text: 'text-white'
  },
  'default': {
    bg: 'bg-black',
    border: 'border-white',
    text: 'text-white'
  }
}

const currentLevelClasses = computed(() => {
  const level = alertStore.level || 'default'
  console.log("[aphex] Using level:", level)
  return levelClasses[level]
})
</script>



<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>