<template>
  <TooltipProvider v-if="allowHover" :delayDuration="250">
    <TooltipRoot>
      <TooltipTrigger as="span" class="inline-block">
        <NuxtLink :to="`/search/${props.type}/${encodeBase64(props.query)}`" class="hover:underline hover:text-white">
          <slot>{{ props.displayText || props.query  }}</slot>{{ props.trailingText }}
        </NuxtLink>
      </TooltipTrigger>
      <TooltipPortal>
        <TooltipContent
          :avoid-collisions="true"
          :side="'bottom'"
          class="data-[state=delayed-open]:data-[side=top]:animate-slideDownAndFade data-[state=delayed-open]:data-[side=right]:animate-slideLeftAndFade data-[state=delayed-open]:data-[side=left]:animate-slideRightAndFade data-[state=delayed-open]:data-[side=bottom]:animate-slideUpAndFade text-grass11 select-none rounded-[4px] bg-white px-[15px] py-[10px] text-[15px] leading-none shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] will-change-[transform,opacity]"
          :side-offset="5">
          {{ props.type }}
          <TooltipArrow class="fill-white" :width="8" />
        </TooltipContent>
      </TooltipPortal>
    </TooltipRoot>
  </TooltipProvider>
  <a v-else :href="`/search/${props.type}/${encodeBase64(props.query)}`" class="group hover:underline hover:text-white">
    <slot>{{ props.query }}</slot>{{ props.trailingText }}
  </a>
</template>

<script setup>

let props = defineProps({
  query: {
    required: true
  },
  type: {
    type: String,
    required: true
  },
  allowHover: {
    type: Boolean,
    default: false
  },
  trailingText: {
    type: String,
    default: ''
  },
  useSlug: {
    type: Boolean,
    default: true
  },
  displayText: {
    type: String,
    default: null
  },
  encode: {
    type: Boolean,
    default: false
  },
  doNotCreateSlug: {
    type: Boolean,
    default: false
  }
})

function encodeBase64(str) {
  if (props.useSlug) {
    return createSlug(str)
  }
  if (props.encode) {
    return btoa(str)
  }
  return str
}

function createSlug(text) {
  if (props.doNotCreateSlug) {
    return text
  }
  if (!text) {
    return ''
  }
  return text
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '') // Remove non-word chars (except spaces and hyphens)
    .replace(/[\s_-]+/g, '-') // Replace spaces, underscores, and hyphens with a single hyphen
    .replace(/^-+|-+$/g, ''); // Remove leading and trailing hyphens
}


</script>