
export function returnHumanizedDate(incomingDate) {
    // Create a new Date object from the input date string
    const date = new Date(incomingDate);

    // Define options for the humanized format
    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    // Use Intl.DateTimeFormat to format the date
    const humanizedDate = new Intl.DateTimeFormat('en-US', options).format(date);
    return humanizedDate;
}


function humanizeDate(dateString) {
    const date = new Date(dateString);
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}


export function returnMSESet(metadata) {
    console.log("[aphex] creating MSE metadata", metadata);

    const artists = metadata.artist;
    const artistString = artists.join(', ');

    // Humanize the date
    const humanizedDate = metadata.date ? humanizeDate(metadata.date) : null;

    // Construct the title
    let title = '';
    if (metadata.festival || metadata.promoter) {
        const titleParts = [];
        if (metadata.festival) titleParts.push(metadata.festival);
        if (metadata.promoter) titleParts.push(metadata.promoter);
        if (humanizedDate) titleParts.push(humanizedDate);
        title = titleParts.join(' · ');
    } else {
        title = artistString;
        if (humanizedDate) {
            title += ` [${humanizedDate}]`;
        }
    }

    // Construct the artist field
    let artist = artistString;
    if (metadata.location) {
        artist += ` · ${metadata.location}`;
    }

    // Construct the album field
    const albumParts = [];
    if (metadata.promoter) albumParts.push(metadata.promoter);
    if (metadata.venue) albumParts.push(metadata.venue);
    if (metadata.festival) albumParts.push(metadata.festival);
    if (metadata.location) albumParts.push(metadata.location);
    if (humanizedDate) albumParts.push(humanizedDate);
    const album = albumParts.join(' · ');

    // Determine the artwork
    const defaultArtwork = 'https://cdn.trackrr.cc/images/public/defaults/DefaultNoImageMSE.png';
    const artworkUrl = metadata.album_art ||
        (metadata.additional_data &&
            metadata.additional_data.artist_profile_pictures &&
            metadata.additional_data.artist_profile_pictures[artists[0]]) ||
        defaultArtwork;

    // Create artwork array for MediaMetadata
    const artwork = [
        { src: artworkUrl, sizes: '512x512', type: 'image/jpeg' }
    ];

    console.log("[aphex] MSE metadata", metadata);
    return {
        title,
        artist,
        album,
        artwork
    };
}

export function returnTabPlayingSet(stream) {
    return returnMSESet(stream.title)
}



export function returnDescriptionForSetSeo(stream) {
    console.log("[aphex] incoming stream", stream.metadata.title);


    const { artist, date, location } = stream.metadata;
    const artists = artist.join(', ');
    const humanizedDate = date ? ` ${returnHumanizedDate(date)}` : '';
    const locationText = location ? ` from ${location}` : '';

    return `Stream ${artists}'s${humanizedDate} set${locationText} on aphex.`;
}