<script setup lang="ts">
import { ref, watch } from 'vue';
import { SpeakerWaveIcon, SpeakerXMarkIcon } from "@heroicons/vue/24/solid";
import { PopoverArrow, PopoverClose, PopoverContent, PopoverPortal, PopoverRoot, PopoverTrigger } from 'radix-vue';

const props = defineProps({
  initialVolume: {
    type: Number,
    default: 1
  },
  isMuted: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['volumeChange', 'muteToggle']);

const volume = ref(props.initialVolume);
const isMuted = ref(props.isMuted);

const handleVolumeChange = (event: Event) => {
  const newVolume = parseFloat((event.target as HTMLInputElement).value);
  volume.value = newVolume;
  if (isMuted.value && newVolume > 0) {
    isMuted.value = false;
    emit('muteToggle', false);
  }
  emit('volumeChange', newVolume);
};

const toggleMute = () => {
  isMuted.value = !isMuted.value;
  emit('muteToggle', isMuted.value);
  if (isMuted.value) {
    emit('volumeChange', 0);
  } else {
    emit('volumeChange', volume.value);
  }
};

watch(() => props.initialVolume, (newVolume) => {
  volume.value = newVolume;
});

watch(() => props.isMuted, (newMutedState) => {
  isMuted.value = newMutedState;
});
</script>

<template>
  <PopoverRoot>
    <PopoverTrigger
      class="text-white hover:text-gray-300 mt-1"
    >
      <SpeakerWaveIcon v-if="!isMuted && volume > 0" class="h-6 w-6" />
      <SpeakerXMarkIcon v-else class="h-6 w-6" />
    </PopoverTrigger>
    <PopoverPortal>
      <PopoverContent
        side="top"
        :side-offset="5"
        class="bg-black border border-white/25 rounded p-2"
      >
        <input
          type="range"
          :value="isMuted ? 0 : volume"
          min="0"
          max="1"
          step="0.01"
          @input="handleVolumeChange"
          class="w-24 accent-white/25"
        />
        <PopoverArrow class="fill-black" />
      </PopoverContent>
    </PopoverPortal>
  </PopoverRoot>
</template>