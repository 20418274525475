<template>
  <button class="rounded-full ransition-colors duration-300 group/likeButton relative" v-if="store.isAuthenticated"
    @click="toggleLike">
    <div class="relative md:h-6 md:w-6 w-8 h-8">
      <template v-if="hasLiked">
        <!-- Liked state: Filled heart -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
          class="absolute inset-0 text-red-500 transition-opacity duration-300 ease-in-out"
          :class="{ 'opacity-100 group-hover/likeButton:opacity-0': hasLiked }">
          <path fill="currentColor"
            d="M256 448a32 32 0 0 1-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8c-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 0 0 9.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64c.54 54.21-18.63 104.26-58.61 153c-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 0 1-18 5.56Z" />
        </svg>
        <!-- Hover state: Dislike heart -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
          class="absolute inset-0 text-white transition-opacity duration-300 ease-in-out"
          :class="{ 'opacity-0 group-hover/likeButton:opacity-100': hasLiked }">
          <path fill="currentColor"
            d="M417.84 448a16 16 0 0 1-11.35-4.72l-365.84-368a16 16 0 1 1 22.7-22.56l365.83 368A16 16 0 0 1 417.84 448ZM364.92 80c-44.09 0-74.61 24.82-92.39 45.5a6 6 0 0 1-9.06 0C245.69 104.82 215.16 80 171.08 80a107.71 107.71 0 0 0-31 4.54l269.13 270.7c3-3.44 5.7-6.64 8.14-9.6c40-48.75 59.15-98.79 58.61-153C475.37 130.53 425.54 80 364.92 80ZM69 149.15a115.06 115.06 0 0 0-9 43.49c-.54 54.21 18.63 104.25 58.61 153c18.77 22.87 52.8 59.45 131.39 112.8a31.88 31.88 0 0 0 36 0c20.35-13.82 37.7-26.5 52.58-38.12Z" />
        </svg>
      </template>
      <template v-else>
        <!-- Not liked state: Outline heart -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
          class="absolute inset-0 text-white transition-opacity duration-300 ease-in-out"
          :class="{ 'opacity-100 group-hover/likeButton:opacity-0': !hasLiked }">
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32"
            d="M352.92 80C288 80 256 144 256 144s-32-64-96.92-64c-52.76 0-94.54 44.14-95.08 96.81c-1.1 109.33 86.73 187.08 183 252.42a16 16 0 0 0 18 0c96.26-65.34 184.09-143.09 183-252.42c-.54-52.67-42.32-96.81-95.08-96.81Z" />
        </svg>
        <!-- Hover state: Filled heart -->
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
          class="absolute inset-0 text-red-500 transition-opacity duration-300 ease-in-out"
          :class="{ 'opacity-0 group-hover/likeButton:opacity-100': !hasLiked }">
          <path fill="currentColor"
            d="M256 448a32 32 0 0 1-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8c-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 0 0 9.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64c.54 54.21-18.63 104.26-58.61 153c-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 0 1-18 5.56Z" />
        </svg>
      </template>
    </div>
  </button>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useStore } from '@/store'
import { useToast } from "@/store/useToastStore"

const props = defineProps({
  setInformation: {
    type: Object,
    required: true
  }
})

const store = useStore()
const toast = useToast()

const componentBasedLiked = ref(false)
const hasLiked = ref(false)


watch(store.userLikedSets, () => {
  hasLiked.value = store.userLikedSets.includes(props.setInformation?.id)
})

store.$subscribe((mutation, state) => {
  hasLiked.value = store.userLikedSets.includes(props.setInformation?.id)
})


onMounted(() => {
  hasLiked.value = store.userLikedSets.includes(props.setInformation?.id) || props.setInformation?.liked
  if (props.setInformation?.liked && !store.userLikedSets.includes(props.setInformation?.id)) {
    store.userLikedSets.push(props.setInformation?.id)
  }
})



async function toggleLike() {
  const newLikedState = !hasLiked.value

  if (newLikedState) {
    // add like
    store.addLike(props.setInformation?.id)
    componentBasedLiked.value = true
    hasLiked.value = true
  } else {
    // remove like
    store.removeLike(props.setInformation?.id)
    componentBasedLiked.value = false
    hasLiked.value = false
  }
}
</script>