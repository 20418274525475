import { useRuntimeConfig } from "#app"


export async function supabaseFetch(input: RequestInfo | URL, init?: RequestInit): Promise<Response> {
  const supabase = useSupabaseClient()
  const runtimeConfig = useRuntimeConfig()


  // Check if we need to use the proper base URL
  if (typeof input === 'string' && input.startsWith('/')) {
    console.log("[aphex] Adding backend URL to request")
    input = runtimeConfig.public.backend + input
  }

  const { data: { session } } = await supabase.auth.getSession()

  const headers = new Headers(init?.headers)
  if (session) {
    console.log("[aphex] Adding access token to request headers")
    headers.set('Authorization', `Bearer ${session.access_token}`)
  } else {
    console.log("[aphex] No access token found")
  }

  const finalInit: RequestInit = {
    ...init,
    headers,
  }

  console.log("[aphex] Final fetch options:", finalInit)

  return fetch(input, finalInit)
}