<template>
  <div class="w-full">
    <div v-if="isDesktop">
      <video ref="videoElement" class="w-full" style="display: none;"></video>
    </div>
    <div v-else>
      <audio ref="audioElement" class="w-full">
        <source :src="currentAudioSrc" type="audio/mpeg" />
      </audio>
    </div>

    <div class="flex items-center space-x-2 p-4 bg-[#0A0A0A] border border-white/25">
      <LikeButton v-if="store.isAuthenticated && showLikes" :set-information="audioPlayerStore.currentTrack" />
      <button @click="toggle" class="text-white hover:text-gray-300 w-12 h-12 flex-shrink-0" :disabled="isLoading">

        <PauseIcon v-if="isLoading" class="animate-pulse text-white/25 rounded-full w-12 h-12" />
        <PlayCircleIcon v-else-if="!isPlaying" class="w-12 h-12" />
        <PauseCircleIcon v-else class="w-12 h-12" />
      </button>

      <div class="flex-grow">
        <div v-if="isLoading" class="animate-pulse border h-2 w-full rounded border-white/25"></div>
        <AudioSeekBar v-else :max="duration" @seek="onSeek" :current-time="currentTime" />
      </div>

      <div class="text-white text-sm text-right flex-shrink-0">
        <div v-if="isLoading" class="animate-pulse border border-white/25 h-4 w-20"></div>
        <template v-else>
          <div class="hidden md:block">{{ formatTime(currentTime) }} / {{ formatTime(duration) }}</div>
        </template>
      </div>

      <div v-if="!isLoading" class="flex items-center space-x-4 flex-shrink-0">
        <div class="">

          <VolumeControlButton :initial-volume="volume" :is-muted="isMuted" @volume-change="onVolumeChange" />

        </div>

      </div>
    </div>
    <div class="border-b border-white/25 w-full p-2 flex gap-2 items-center justify-center md:hidden">
      <span class="text-white text-xs uppercase tracking-widest align-center">
        {{ formatTime(currentTime) }} / {{ formatTime(duration) }}
      </span>
    </div>
    <AudioTrackIDDisplay v-if="trackIds.length > 0" :track-ids="trackIds" :current-time="currentTime" />
    <div v-if="debug" class="mt-2 text-sm text-gray-500">
      <div>Current Source: {{ currentAudioSrc }}</div>
      <div>Is Desktop: {{ isDesktop }}</div>
      <div>Is Loading: {{ isLoading }}</div>
      <div>Volume: {{ volume }}</div>
      <div v-for="(message, index) in debugMessages" :key="index">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick } from 'vue';
import { PlayCircleIcon, PauseCircleIcon, ArrowTopRightOnSquareIcon } from '@heroicons/vue/24/solid';
import { PlayCircleIcon as PlayIcon, PauseCircleIcon as PauseIcon } from '@heroicons/vue/24/outline';
import { useAudioPlayerStore, useStore } from '~/store';
import { useEventBus, useTitle } from '@vueuse/core';
import { onKeyStroke } from '@vueuse/core'
import { useToast } from '@/store/useToastStore'
import mixpanel from 'mixpanel-browser'




const toast = useToast()
const props = defineProps({
  isDesktop: {
    type: Boolean,
    default: false
  },
  debug: {
    type: Boolean,
    default: false
  },
  showLikes: {
    type: Boolean,
    default: true
  },
  overrideUrl: {
    type: String,
    default: null
  },
  localMode: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['paused', 'playing', 'position', 'error']);

const audioPlayerStore = useAudioPlayerStore();
const router = useRouter()
const videoElement = ref(null);
const audioElement = ref(null);
const player = ref(null); // This will hold either the Dash player or the audio element
const currentTime = ref(0);
const duration = ref(0);
const isLoading = ref(true);
const debugMessages = ref(['Initializing...']);
const store = useStore()
const currentSrc = computed(() => audioPlayerStore.getCurrentSrc);
const volume = computed(() => audioPlayerStore.getVolume);
const isMuted = computed(() => audioPlayerStore.getIsMuted);

let localStatePlaying = ref(false);

const isPlaying = computed(() => {
  if (!props.localMode) {
    return audioPlayerStore.getIsPlaying
  } else {
    return localStatePlaying.value
  }
});


function shouldRedirectBackToSourcePage() {
  if (!isOnStreamPage.value) {
    router.push(`/stream/${audioPlayerStore.currentTrack.id}`)
  }
}



const isOnStreamPage = computed(() => router.currentRoute.value.path.includes('/stream/'))


const currentAudioSrc = computed(() => {

  if (props.localMode && props.overrideUrl) {
    addDebugMessage(`!!! Overriding URL: ${props.overrideUrl} !!!!`);
    return props.overrideUrl;
  }

  if (!props.isDesktop && audioPlayerStore.currentTrack?.download_url) {
    return audioPlayerStore.currentTrack.download_url;
  }
  return audioPlayerStore.getCurrentSrc;
});


const trackIds = computed(() => {
  if (audioPlayerStore.currentTrack?.trackIds) {
    return audioPlayerStore.currentTrack.trackIds;
  }
  return [];
});



const audioPlayerBus = useEventBus('audio-player');

const addDebugMessage = (message) => {
  debugMessages.value.push(`${new Date().toISOString()}: ${message}`);
  if (debugMessages.value.length > 50) {
    debugMessages.value.shift();
  }
  console.log(`[AudioPlayer Debug] ${message}`);
};

const initializeDashPlayer = async () => {
  if (!videoElement.value) {
    addDebugMessage('Video element not found');
    return;
  }

  try {
    addDebugMessage('Loading dash.js...');
    const dashjs = await import('dashjs');
    addDebugMessage('dash.js loaded');

    const dashPlayer = dashjs.MediaPlayer().create();

    dashPlayer.updateSettings({
      'streaming': {
        'abr': {
          'autoSwitchBitrate': {
            'audio': true,
            'video': true
          }
        },
        'buffer': {
          'stableBufferTime': 20,
          'bufferTimeAtTopQuality': 30
        },
        'fastSwitchEnabled': true
      },
      'debug': {
        'logLevel': dashjs.Debug.LOG_LEVEL_WARNING
      }
    });


    const manifestUrl = currentSrc.value;
    addDebugMessage(`Initializing with manifest URL: ${manifestUrl}`);


    if (props.localMode) {
      dashPlayer.initialize(videoElement.value, props.overrideUrl, false);
    } else {
      dashPlayer.initialize(videoElement.value, manifestUrl, false);
    }
    dashPlayer.attachView(videoElement.value);
    addDebugMessage('dash.js player initialized');

    dashPlayer.on('error', (e) => {
      addDebugMessage(`Dash player error: ${JSON.stringify(e)}`);
      emit('error', e);
      fallbackToAudio();
    });

    dashPlayer.on('canPlay', () => {
      addDebugMessage('Dash player can play');
      duration.value = dashPlayer.duration();
      isLoading.value = false;

      if (props.localMode) {
        // do not autoplay
      } else {
        attemptPlay();
      }
    });

    dashPlayer.on('playbackStarted', () => {
      addDebugMessage('Playback has started');
      emit('playing');
      // Set initial volume and mute state
      dashPlayer.setVolume(audioPlayerStore.getVolume);
      dashPlayer.setMute(audioPlayerStore.getIsMuted);
      if (props.localMode) {
        localStatePlaying.value = true;
      }
    });

    dashPlayer.on('playbackPaused', () => {
      addDebugMessage('Playback has paused');
      emit('paused');
      if (props.localMode) {
        localStatePlaying.value = false;
      }
    });

    dashPlayer.on('playbackTimeUpdated', (e) => {
      currentTime.value = e.time;
      audioPlayerStore.setPosition(e.time);
      emit('position', e.time);
    });

    dashPlayer.on('playbackEnded', () => {
      addDebugMessage('Playback has ended');
    });

    player.value = dashPlayer;
    audioPlayerStore.player = dashPlayer;
  } catch (error) {
    addDebugMessage(`Error initializing dash.js: ${error.message}`);
    emit('error', error);
    fallbackToAudio();
  }
};

const fallbackToAudio = () => {
  if (audioPlayerStore.currentTrack?.download_url) {
    addDebugMessage('Falling back to MP3 URL');
    audioElement.value.src = audioPlayerStore.currentTrack.download_url;
    player.value = audioElement.value;
    attemptPlay();
  }
};





const attemptPlay = () => {
  if (player.value) {
    addDebugMessage('Attempting to play');
    const playPromise = player.value.play();
    if (playPromise !== undefined) {
      playPromise.then(() => {
        addDebugMessage('Play started successfully');
        if (props.localMode) {
          // we will only emit this if we're in local mode
          emit('playing');
          localStatePlaying.value = true;
        } else {
          audioPlayerStore.setPlayerState('playing');
        }
      }).catch(error => {
        addDebugMessage(`Play error: ${error.message}`);
        emit('error', error);
      });
    } else {
      addDebugMessage('Play started (no promise returned)');
      if (props.localMode) {
        // we will only emit this if we're in local mode
        emit('playing');
        localStatePlaying.value = true;
      } else {
        audioPlayerStore.setPlayerState('playing');
      }
    }
  } else {
    addDebugMessage('No player available for play attempt');
  }

  const attemptPause = () => {
    if (player.value) {
      addDebugMessage('Attempting to pause');
      const playPromise = player.value.pause();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          addDebugMessage('pause started successfully');
          if (props.localMode) {
            // we will only emit this if we're in local mode
            emit('pause');
            localStatePlaying.value = false;
          } else {
            audioPlayerStore.setPlayerState('paused');
          }
        }).catch(error => {
          addDebugMessage(`paused error: ${error.message}`);
          emit('error', error);
        });
      } else {
        addDebugMessage('paused started (no promise returned)');
        if (props.localMode) {
          // we will only emit this if we're in local mode
          emit('paused');
          localStatePlaying.value = true;
        } else {
          audioPlayerStore.setPlayerState('paused');
        }
      }
    } else {
      addDebugMessage('No player available for pause attempt');
    }
  }



  if (audioPlayerStore.playerState === 'playing') {
    useTitle(`▶ ${audioPlayerStore.currentTrack.metadata.title} | aphex`)
    // Configure the MSE metadata
    try {
      var metadataCreated = new MediaMetadata({
        ...returnMSESet(audioPlayerStore.currentTrack.metadata)
      });
      console.log("[aphex] metadata created for MSE", metadataCreated)
      // Set the metadata * and allow cctions
      navigator.mediaSession.metadata = metadataCreated;

      navigator.mediaSession.setActionHandler('play', function (event) {
        console.log("[aphex] play", event)
        attemptPlay()
      });
      navigator.mediaSession.setActionHandler('pause', function (event) {
        console.log("[aphex] pause", event)
        attemptPause()
      });


      navigator.mediaSession.setActionHandler('seekbackward', function (event) {
        console.log("[aphex] seekbackward", event)
        audioPlayerStore.seek(audioPlayerStore.position - 15)
      });
      navigator.mediaSession.setActionHandler('seekforward', function (event) {
        console.log("[aphex] seekforward", event)
        audioPlayerStore.seek(audioPlayerStore.position + 15)
      })



    } catch (error) {
      console.error("[aphex] error creating MSE metadata", error)
    }
  }



};

const initializeAudioElement = () => {
  player.value = audioElement.value;
  if (player.value) {
    player.value.addEventListener('loadedmetadata', () => {
      addDebugMessage('Metadata loaded', player.value.duration);
      duration.value = player.value.duration;
      isLoading.value = false;
      attemptPlay();
    });

    player.value.addEventListener('canplay', () => {
      addDebugMessage('Audio can play');
      isLoading.value = false;
      // Set initial volume and mute state
      player.value.volume = audioPlayerStore.getVolume;
      player.value.muted = audioPlayerStore.getIsMuted;
      if (audioPlayerStore.playerState === 'playing') {
        attemptPlay();
      }
    });

    player.value.addEventListener('timeupdate', () => {
      currentTime.value = player.value.currentTime;

      if (!props.localMode) {
        audioPlayerStore.setPosition(player.value.currentTime);
      }
      // audioPlayerStore.setPosition(player.value.currentTime);
      emit('position', player.value.currentTime);
    });

    player.value.addEventListener('play', () => {
      addDebugMessage('Playing');
      emit('playing');
      if (!props.localMode) {
        audioPlayerStore.setPlayerState('playing');
      } else {
        localStatePlaying.value = true;
      }
    });

    player.value.addEventListener('pause', () => {
      addDebugMessage('Paused');
      emit('paused');
      if (!props.localMode) {
        audioPlayerStore.setPlayerState('paused');
      } else {
        localStatePlaying.value = false;
      }
    });

    player.value.addEventListener('error', (e) => {
      addDebugMessage(`Error: ${e.target.error.message}`);
      emit('error', e.target.error);
      isLoading.value = false;
    });

    audioPlayerStore.player = player.value;
  }
};

onMounted(async () => {

  if (props.localMode && props.overrideUrl) {
    addDebugMessage(`!!! Overriding URL: ${props.overrideUrl} !!!!`);
    currentAudioSrc.value = props.overrideUrl;
    addDebugMessage('Killing current playing track');
    try {
      audioPlayerStore.killCurrentPlayingTrack();
    } catch (error) {

    }
  }


  addDebugMessage(`Mounted. Is Desktop: ${props.isDesktop}`);
  if (props.overrideUrl) {
    addDebugMessage(`Overriding URL: ${props.overrideUrl}!!!!`);
  }
  if (props.localMode) {
    addDebugMessage(`!!! Local mode enabled !!!!`);
  }

  if (props.isDesktop) {
    videoElement.value.style.display = 'block';
    videoElement.value.style.width = '1px';
    videoElement.value.style.height = '1px';

    await nextTick();
    initializeDashPlayer();
  } else {
    addDebugMessage("Not desktop, initializing audio element");
    initializeAudioElement();
  }


  audioPlayerBus.on((eventName) => {
    console.log(`[aphex] audio player bus event: ${eventName}`);
    addDebugMessage(`[aphex] audio player bus event: ${eventName}`);
    switch (eventName) {
      case 'play-track':
        togglePlay();
        break;
      case 'pause-track':
        togglePause();
        break;
      case 'resume-track':
        togglePlay();
        break;
      case 'set-volume':
        console.log(`[aphex] set-volume event received`);
        break;
      case 'toggle-mute':
        console.log(`[aphex] toggle-mute event received`);
        break;
      default:
        console.log(`[aphex] unhandled event: ${eventName}`);
        /* Check if the event name is set-current-time: */
        if (eventName.startsWith('set-current-time:')) {
          const newTime = eventName.split(':')[1];
          console.log(`[aphex] set-current-time event received: ${newTime}`, 'parsed from event name', eventName);
          onSeek(newTime);
          break;
        }



        break;
    }
  });


  if (!props.localMode) {
    onKeyStroke(' ', (e) => {
      if (audioPlayerStore.ignoreKeyboardShortcuts) {
        console.log("[aphex] ignoring keyboard shortcut")
        return
      }


      e.preventDefault();
      console.log("[aphex] spacebar pressed", audioPlayerStore.playerState)
      if (audioPlayerStore.playerState === 'playing') {
        console.log("[aphex] spacebar pressed, toggling pause")
        audioPlayerStore.pause();
      } else {
        console.log("[aphex] spacebar pressed, toggling play")
        audioPlayerStore.play();
      }
    })
    // Check if we need to fetch the track IDs, 
    if (audioPlayerStore.currentTrack?.trackIdsAvailable && audioPlayerStore.currentTrack.trackIds.length == 0) {
      console.log("[aphex] Fetching missing track ids", audioPlayerStore.currentTrack.id)
      try {
        const response = await supabaseFetch(`/user/manage/track-ids/get/${audioPlayerStore.currentTrack.id}`, {
          method: 'GET',
        });
        const trackIdsResponse = await response.json();
        audioPlayerStore.currentTrack.trackIds = trackIdsResponse.track_ids;
        console.log("[aphex]", "got track ids", trackIdsResponse)
      } catch (error) {
        console.error("Error fetching track ids:", error);
      }
    }
  }
});

const togglePause = () => {
  addDebugMessage(`Toggle pause called. Is Loading: ${isLoading.value}, Is Playing: ${isPlaying.value}`);
  if (isLoading.value) return;

  addDebugMessage(`player.value: ${player.value}`);


  if (player.value) {
    console.log('[aphex] Pausing player');
    player.value.pause();
    if (!props.localMode) {
      addDebugMessage('Pause requested');
      audioPlayerStore.setPlayerState('paused');
    } else {
      localStatePlaying.value = false;
    }
  } else {
    addDebugMessage('No player available for toggle pause');
  }

  if (audioPlayerStore.playerState === 'paused') {
    useTitle(`⏸ ${audioPlayerStore.currentTrack.metadata.title} | aphex`)
    if (!props.localMode) {
      mixpanel.track('Paused Set', { set: audioPlayerStore.currentTrack.id })
    }
    //mixpanel.track('Paused Set', { set: audioPlayerStore.currentTrack.id })
  }
};

const togglePlay = () => {
  addDebugMessage(`Toggle play called. Is Loading: ${isLoading.value}, Is Playing: ${isPlaying.value}`);
  if (isLoading.value) return;
  addDebugMessage(`player.value: ${player.value}`);
  if (player.value) {
    console.log('[aphex] Toggling play');
    attemptPlay();
  } else {
    addDebugMessage('No player available for toggle play');
  }
  if (audioPlayerStore.playerState === 'playing') {
    useTitle(`▶ ${audioPlayerStore.currentTrack.metadata.title} | aphex`)
    if (!props.localMode) {
      mixpanel.track('Playing Set', { set: audioPlayerStore.currentTrack.id })
    }
    //mixpanel.track('Playing Set', { set: audioPlayerStore.currentTrack.id })
  }
};

const toggle = async () => {
  if (isLoading.value) return;
  addDebugMessage(`player.value: ${player.value}`);
  if (player.value) {
    if (isPlaying.value) {
      console.log('[aphex] Pausing player');
      player.value.pause();
      addDebugMessage('Pause requested');
      audioPlayerStore.setPlayerState('paused');
    } else {
      const playPromise = player.value.play();
      if (playPromise !== undefined) {
        playPromise.then(() => {
          addDebugMessage('Play started successfully');
          audioPlayerStore.setPlayerState('playing');
        }).catch(error => {
          addDebugMessage(`Play error: ${error.message}`);
          emit('error', error);
        });
      } else {
        addDebugMessage('Play started (no promise returned)');
        audioPlayerStore.setPlayerState('playing');
      }
    }
  } else {
    addDebugMessage('No player available for toggle play');
  }
}


const onVolumeChange = (newVolume) => {
  audioPlayerStore.setVolume(newVolume);
  if (player.value) {
    if (props.isDesktop) {
      player.value.setVolume(newVolume);
    } else {
      player.value.volume = newVolume;
    }
    addDebugMessage(`Volume changed to ${newVolume}`);
  }
};

const onMuteToggle = (muteState) => {
  audioPlayerStore.toggleMute();
  if (player.value) {
    if (props.isDesktop) {
      player.value.setMute(muteState);
    } else {
      player.value.muted = muteState;
    }
    addDebugMessage(`Mute toggled to ${muteState}`);
  }
};

const onSeek = (event) => {
  addDebugMessage(`Recieved seek event: ${event} -- ${typeof event}`);
  if (isLoading.value) {
    addDebugMessage(`Seek event ignored -- is loading`);
  };
  const newTime = parseFloat(event);
  addDebugMessage(`Seeking to ${newTime}`);
  if (player.value) {
    if (props.isDesktop) {
      player.value.seek(newTime);
    } else {
      player.value.currentTime = newTime;
    }
    currentTime.value = newTime;
    addDebugMessage(`Seeked to ${newTime}`);
  }

  if (!props.localMode) {
    mixpanel.track('Seeked Set', { set: audioPlayerStore.currentTrack.id, time: newTime })
  }


};

const formatTime = (time) => {
  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  if (hours > 0) {
    return `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  } else {
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  }
};

watch(() => audioPlayerStore.getCurrentSrc, (newSrc) => {
  addDebugMessage(`Source updated from store: ${newSrc}`);
  isLoading.value = true;
  if (props.isDesktop && player.value) {
    player.value.attachSource(newSrc);
  } else if (audioElement.value) {
    audioElement.value.src = currentAudioSrc.value;
    audioElement.value.load();
    if (audioPlayerStore.playerState === 'playing') {
      attemptPlay();
    }
  }
});

watch(() => audioPlayerStore.getVolume, (newVolume) => {
  if (player.value) {
    if (props.isDesktop) {
      player.value.setVolume(newVolume);
    } else {
      player.value.volume = newVolume;
    }
    addDebugMessage(`Volume updated from store: ${newVolume}`);
  }
});

watch(() => audioPlayerStore.getIsMuted, (newMutedState) => {
  if (player.value) {
    if (props.isDesktop) {
      player.value.setMute(newMutedState);
    } else {
      player.value.muted = newMutedState;
    }
    addDebugMessage(`Mute state updated from store: ${newMutedState}`);
  }
});

// Expose methods for parent component
defineExpose({
  play: () => {
    addDebugMessage('Play method called');
    if (isLoading.value) return;
    attemptPlay();
  },
  pause: () => {
    addDebugMessage('Pause method called');
    if (isLoading.value) return;
    if (player.value && typeof player.value.pause === 'function') {
      player.value.pause();
    }
  },
  skipTo: (position) => {
    addDebugMessage(`Skip to ${position} called`);
    if (isLoading.value) return;
    if (player.value) {
      if (props.isDesktop) {
        player.value.seek(position);
      } else {
        player.value.currentTime = position;
      }
      currentTime.value = position;
    }
  },
  setVolume: (newVolume) => {
    addDebugMessage(`Set volume to ${newVolume} called`);
    onVolumeChange(newVolume);
  }
});





</script>