<template>
    <div v-if="currentTrackId?.title && displayTrackId" class="border-b border-white/25 w-full p-2 flex gap-2 items-center justify-center">
      <span class="text-white/50 text-xs uppercase tracking-widest align-center">
        Track Id:
      </span>
      <span class="text-white text-xs uppercase tracking-widest align-center">
        {{ currentTrackId.title }} by {{ currentTrackId.artist }}
      </span>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, watch } from 'vue'
  
  const props = defineProps({
    trackIds: {
      type: Array,
      required: true
    },
    currentTime: {
      type: Number,
      required: true
    }
  })
  
  const currentTrackId = ref(null)
  const displayTrackId = ref(false)
  const displayTimeout = ref(null)
  
  const sortedTrackIds = computed(() => {
    return props.trackIds
      .filter(trackId => trackId.starting_position !== null)
      .sort((a, b) => a.starting_position - b.starting_position)
  })
  
  const findCurrentTrack = (time) => {
    return sortedTrackIds.value.find((track, index) => {
      const nextTrack = sortedTrackIds.value[index + 1]
      return track.starting_position <= time && 
             (!nextTrack || time < nextTrack.starting_position)
    })
  }
  
  const isNextTrackSoon = (currentTime) => {
    const currentIndex = sortedTrackIds.value.findIndex(track => track === currentTrackId.value)
    const nextTrack = sortedTrackIds.value[currentIndex + 1]
    var result = nextTrack && (nextTrack.starting_position - currentTime) <= 20
    console.log('[aphex]', ['trackIds'], 'isNextTrackSoon', result)
  }
  
  const updateTrackIdDisplay = (newTrack, time) => {
    if (newTrack !== currentTrackId.value) {
      currentTrackId.value = newTrack
      displayTrackId.value = true
  
      if (displayTimeout.value) {
        clearTimeout(displayTimeout.value)
      }
  
      displayTimeout.value = setTimeout(() => {
        if (!isNextTrackSoon(time + 20)) {
          displayTrackId.value = false
        }
      }, 20000)
    }
  }
  
  watch(() => props.currentTime, (newTime) => {
    const newTrack = findCurrentTrack(newTime)
    updateTrackIdDisplay(newTrack, newTime)
  }, { immediate: true })
  
  // Expose method to manually update current track
  const updateCurrentTrack = (time) => {
    const newTrack = findCurrentTrack(time)
    updateTrackIdDisplay(newTrack, time)
  }
  
  defineExpose({ updateCurrentTrack })
  </script>