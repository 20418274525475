<template>

    <div>
        <NuxtLayout>
            <template #header>
                <Header>
                    <template #header>
                        <span v-if="error.statusCode == 404">404.</span>
                        <span v-else>F*$#!</span>
                    </template>
                    <template #subheader>
                        <span class="text-white/50" v-if="error.statusCode == 404">not sure what you're looking for here.</span>
                        <span class="text-sm text-white/50" v-else>
                            <span class="text-white/50">Something went wrong, and our engineers have been alerted..</span>
                            <p>
                                <strong>{{ error.message }}</strong>
                            </p>

                        </span>
                    </template>
                </Header>
            </template>
            <template #content>
                <a href="/" class="text-white hover:underline">go back home</a>
            </template>
        </NuxtLayout>
    </div>


</template>
<script setup>
const error = useError();

</script>

<style lang="scss" scoped></style>